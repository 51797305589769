.quote {
  font-family: 'Arial', sans-serif;
}

.quote h2 {
  text-align: center;
  margin-bottom: 20px;
}

.quote-table,
.quote-table th,
.quote-table td {
  border: 1px solid #4caf50;
  border-collapse: collapse;
}

.quote-table {
  width: 100%;
  margin-bottom: 20px;
}

.quote-table th,
.quote-table td {
  padding: 10px;
  text-align: left;
}

.quote-table th {
  background-color: #2e7d32;
  color: white;
}

.quote-button {
  display: block;
  margin: 0 auto;
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
}

.quote-button:hover {
  background-color: #2e7d32;
}